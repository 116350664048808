import { Container, Row, Col } from "react-bootstrap";
import ModalComponent from "./ProjectCardComponent";
import { projects } from "../data/constants";

export const Project = () => {
  return (
    <section className="project py-3 mb-5" id="projects">
      <h2>Projects</h2>
      <Container className="pt-3">
        <Row>
          {projects.map((project) => (
            <Col md={4} className="py-2">
              <ModalComponent project={project} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};
