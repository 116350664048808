import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiMedium } from "react-icons/si";
import { FaCloudDownloadAlt } from "react-icons/fa";

import profile2 from "../assets/img/profile_pic.JPG";

export const About = () => {
  return (
    <section className="about py-3 mb-5" id="about">
      <h2>About Me</h2>
      <Container className="pt-3">
        <Row className="d-flex align-items-center justify-content-center">
          <Col xs={12} md={8} className="order-last order-md-first">
            <div className="content">
              Full Stack React Native Developer with 6 years of experience and having
              worked in fast-paced startup environments. I specialize in React,
              React Native, Node.js, REST API design and integration, Unit
              testing, and Database design and development.
              <br />
              <br />
              I have built 7 react native applications for high-paced businesses
              and startups. In my current company in Singapore, I implemented
              and maintained a top HR application that increased sales. The app
              has more than 100,000 downloads and 10,000 daily active users.
              <br />
              <br />I am committed to continuous improvement and learning. If
              you're looking for a motivated and results-oriented software
              engineer, I'd love to connect with you.
            </div>
            <div className="d-flex justify-content-start align-items-center mt-4">
              <a
                href="https://drive.google.com/file/d/18_R1pV257Cfbh5BlpiquMYX9O7xZMF4N/view?usp=sharing"
                type="application/pdf"
                className="btn btn-light d-flex justify-content-center align-items-center me-3"
                target="_blank"
              >
                Download Resume
                <FaCloudDownloadAlt className="ms-2" />
              </a>

              <a
                href="https://linkedin.com/in/prakash-subedi10"
                target="_blank"
                style={{ fontSize: "30px", color: "#fff" }}
              >
                <FaLinkedin />
              </a>
              <a
                href="https://github.com/kashsbd"
                target="_blank"
                className="ps-3"
                style={{ fontSize: "30px", color: "#fff" }}
              >
                <FaGithub />
              </a>
              <a
                href="https://x.com/KashSbd"
                target="_blank"
                className="ps-3"
                style={{ fontSize: "30px", color: "#fff" }}
              >
                <FaSquareXTwitter />
              </a>
              <a
                href="https://medium.com/@prakashsubedi_88421"
                target="_blank"
                className="ps-3"
                style={{ fontSize: "30px", color: "#fff" }}
              >
                <SiMedium />
              </a>
            </div>
          </Col>
          <Col xs={12} md={4} className="order-first order-md-last mb-3">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={profile2} // Replace with your photo URL
                alt="Photo"
                style={{
                  borderRadius: "50%",
                  width: "320px",
                  height: "320px",
                  objectFit: "contain",
                  border: "1px solid #fff",
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
