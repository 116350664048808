import { Container } from "react-bootstrap";
import { FaJenkins } from "react-icons/fa";

import kotlin from "../assets/img/kotlinlang-icon.svg";
import java from "../assets/img/java-icon.svg";
import postgreSQL from "../assets/img/postgres.png";
import javascript from "../assets/img/javascript-icon.svg";
import nodeJs from "../assets/img/nodejs-icon.svg";
import reactJs from "../assets/img/reactjs-icon.svg";
import bootstrap from "../assets/img/getbootstrap-icon.svg";
import mongoDb from "../assets/img/mongodb-icon.svg";
import spring from "../assets/img/springio-icon.svg";
import css from "../assets/img/css.png";
import html from "../assets/img/w3_html5-icon.svg";
import git from "../assets/img/git-scm-icon.svg";
import gitHub from "../assets/img/github-icon.svg";
import docker from "../assets/img/docker-icon.svg";
import restapi from "../assets/img/restapi.jpg";

export const Skill = () => {
  return (
    <section className="skill py-3 mb-5" id="skills">
      <h2>Skills</h2>
      <Container className="pt-3">
        <div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={java} /> Java
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={kotlin} /> Kotlin
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={javascript} /> JavaScript
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={reactJs} /> React JS
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={nodeJs} /> Node JS
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={spring} /> Spring Boot
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={bootstrap} /> Bootstrap
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={mongoDb} /> MongoDB
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={html} /> HTML
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={css} /> CSS
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={postgreSQL} /> PostgreSQL
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={git} /> Git
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={gitHub} /> GitHub
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <FaJenkins /> Jenkins
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={docker} /> Docker
          </div>
          <div className="btn text-white btn-floating m-1 me-3 skill-item">
            <img src={restapi} /> REST
          </div>
        </div>
        <hr />
      </Container>
    </section>
  );
};
