import { FaLinkedin } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { SiMedium } from "react-icons/si";

import profile2 from "../assets/img/profile_pic.JPG";

export const ContactInfo = () => {
  return (
    <div className="card contact-info">
      <div
        className="card-img-top d-none d-md-block"
        style={{ marginBottom: "110px" }}
      >
        <img src={profile2} />
      </div>
      <div className="card-body text-center">
        <div className="card-text">
          <div>
            <span className="text-secondary">Phone:</span> +1(641)233-9586
          </div>
          <div>
            <span className="text-secondary">Email:</span>{" "}
            <a
              href="mailto:prakash.subedi10@outlook.com"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              prakash.subedi10@outlook.com
            </a>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <a
            href="https://linkedin.com/in/prakash-subedi10"
            target="_blank"
            style={{ fontSize: "30px", color: "#fff" }}
          >
            <FaLinkedin />
          </a>
          <a
            href="https://github.com/kashsbd"
            target="_blank"
            className="ps-3"
            style={{ fontSize: "30px", color: "#fff" }}
            rel="noreferrer"
          >
            <FaGithub />
          </a>
          <a
            href="https://x.com/KashSbd"
            target="_blank"
            className="ps-3"
            style={{ fontSize: "30px", color: "#fff" }}
          >
            <FaSquareXTwitter />
          </a>
          <a
            href="https://medium.com/@prakashsubedi_88421"
            target="_blank"
            className="ps-3"
            style={{ fontSize: "30px", color: "#fff" }}
          >
            <SiMedium />
          </a>
        </div>
      </div>

      <div></div>
    </div>
  );
};
