import React, { useState, useEffect } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';


function ModalComponent({ project }) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const loadImage = async () => {
            const imageModule = await import(`../assets/img/${project.image.path}`);
            setImageSrc(imageModule.default);
        };

        loadImage();
    }, [project.image]);



    return (
        <>
            <Card className="card p-3 bg-dark text-white" style={{ boxShadow: '0 0 50px 4px rgba(0,0,0,0.6)' }}>
                {imageSrc && <Card.Img className={project.image.isContain ? 'product-card-image contain' : 'product-card-image'} src={imageSrc} style={{ borderRadius: '10px' }} />}
                <Card.Body>
                    <Card.Text>
                        <div>
                            {project.tags.map((tag, index) => (
                                <span key={index} className="badge bg-secondary text-dark me-1">{tag}</span>
                            ))}
                        </div>
                        <div className='mt-3' style={{ fontSize: '1.3rem', fontWeight: '600' }}>
                            {project.title}
                        </div>
                        <div className="mt-2">
                            {project.description}
                        </div>
                    </Card.Text>

                    <div className="d-flex align-items-center justify-content-between">
                        {project.github && <div>
                            <a className="btn btn-info btn-sm" href={project.github} target="_blank">GitHub Repo</a>
                        </div>}

                        {project.webapp && <div>
                            <a className="btn btn-info btn-sm" href={project.webapp} target="_blank">View App</a>
                        </div>}

                    </div>
                </Card.Body>
            </Card>

            {/* <Modal show={showModal} onHide={handleCloseModal} centered style={{ color: 'black' }}>
                <Modal.Header closeButton>
                    <Modal.Title>MMBusTicket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>A platform for bus ticket system in Myanmar</p>
                    <p>Tasks</p>
                    <ul>
                        <li>Implemented security features (Login Device Detection, Force Logout, Rate Limit) </li>
                        <li>Enhanced Manaul re-booking feature and added comment feature</li>
                        <li>Integrated with promotional coupon and voucher code management through REST API</li>
                    </ul>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </>
    );
}

export default ModalComponent;
