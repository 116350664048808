export const projects = [
    {
        id: 1,
        title: "PayBoy",
        description: "An application to manage HR-related tasks",
        image: {
            path: "payboy.jpg",
            isContain: false
        },
        tags: [
            "Ruby on Rails",
            "JavaScript",
            "TypeScript",
            "React Js",
            "React Native",
            "PostgreSQL",
            "REST API"
        ],
        github: "",
        webapp: "https://www.payboy.biz/"
    },
    {
        id: 2,
        title: "E360",
        description: " a case management software system for faster productivity and better managed legal records for professional lawyers and law firms.",
        image: {
            path: "e360.jpg",
            isContain: false
        },
        tags: [
            "Java",
            "Play Framework",
            "PostgreSQL",
            "JavaScript",
            "HTMX",
            "Bootstrap",
            "REST API",
            "JUnit",
            "Mockito"
        ],
        github: "",
        webapp: "https://lvmt.co/"
    }
]