import { Container, Row, Col } from "react-bootstrap";
import closure from "../assets/img/closures.jpg";
import promise from "../assets/img/promise.jpg";

export const Blog = () => {
  return (
    <section className="py-3 mb-5 blogs" id="blogs">
      <h2>Blogs</h2>
      <Container className="pt-4">
        <Row>
          <Col sm={12} md={6} lg={4} className="mb-2">
            <div className="card p-3">
              <img src={closure} className="card-image" alt="..." />
              <div className="card-body">
                <h5 className="card-title">
                  Mastering Closures in JavaScript: A Complete Guide
                </h5>
                <a
                  href="https://medium.com/@prakashsubedi_88421/mastering-closures-in-javascript-a-complete-guide-be27d4b9d59a"
                  target="_blank"
                >
                  Read More...
                </a>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} className="mb-2">
            <div className="card p-3">
              <img
                src={promise}
                className="card-image"
                alt="..."
                style={{ objectFit: "contain" }}
              />
              <div className="card-body">
                <h5 className="card-title">
                  Understanding Promises in JavaScript: A Comprehensive Guide
                </h5>
                <a
                  href="https://medium.com/@prakashsubedi_88421/understanding-promises-in-javascript-a-comprehensive-guide-3d42814415a6"
                  target="_blank"
                >
                  Read More...
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
