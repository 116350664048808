
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from './components/NavBar';
import { Banner } from './components/Banner';
import { About } from './components/Aboutme';
import { Skill } from './components/Skill';
import { Blog } from './components/Blogs';
import { Contact } from './components/Contact';
import Summary from './components/Experience';
import { Footer } from './components/Footer';
import { Project } from './components/Projects';


function App() {
  return (
    <div>
      <NavBar />
      <Banner />
      <About />
      <Skill />
      <Summary />
      <Project />
      <Blog />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
