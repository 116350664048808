import React from "react";
import { MDBContainer } from "mdb-react-ui-kit";
import { Container, Row, Col } from "react-bootstrap";

export default function Summary() {
  return (
    <section className="education py-3 mb-5" id="experience">
      <Container className="pt-3">
        <h2>Education & Experience</h2>
        <Row>
          <Col sm={12} md={6}>
            <h5 className="pt-3">Education</h5>
            <MDBContainer className="pt-2">
              <ul className="timeline">
                <li className="timeline-item mb-5">
                  <div className="d-flex">
                    <div>
                      <h5 className="fw-bold">
                        Maharishi International University
                      </h5>
                      <p className="mb-2 fw-bold">
                        Master of Science in Computer Science
                      </p>
                      <p>Apr 2023 - Dec 2025</p>
                    </div>
                  </div>
                </li>

                <li className="timeline-item mb-5">
                  <div className="d-flex">
                    <div>
                      <h5 className="fw-bold">
                        Computer University (Mandalay)
                      </h5>
                      <p className="mb-2 fw-bold">
                        Bachelor of Computer Science
                      </p>
                      <p>Dec 2012 - Oct 2018</p>
                    </div>
                  </div>
                </li>
              </ul>
            </MDBContainer>
          </Col>
          <Col col={12} md={6}>
            <h5 className="pt-3">Experience</h5>
            <MDBContainer className="pt-2">
              <ul className="timeline">
                <li className="timeline-item mb-5">
                  <h5 className="fw-bold">React Native Developer | Payboy</h5>
                  <p className="mb-2 fw-bold">
                    Payboy is a smart HRM SaaS platform.
                  </p>
                  <p>08/2022 – PRESENT</p>
                </li>

                <li className="timeline-item mb-5">
                  <h5 className="fw-bold">Full Stack Developer | LVM TECH</h5>
                  <p className="mb-2 fw-bold">
                    LVMT provides case management software for individual
                    lawyers and law firms.
                  </p>
                  <p>08/2020 – 07/2022</p>
                </li>

                <li className="timeline-item mb-5">
                  <h5 className="fw-bold">
                    Junior Developer | MARTER SOLUTIONS
                  </h5>
                  <p className="mb-2 fw-bold">
                    Marter Solutions is a software consultancy and software
                    development company.
                  </p>
                  <p>10/2016 – 08/2019</p>
                </li>
              </ul>
            </MDBContainer>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
